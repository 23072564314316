import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import moment from 'moment';
import packageJson from 'package.json';
import { BehaviorSubject, Subject, throwError } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { CustomerSelectorsService } from 'src/app/services/customer-selectors/customer-selectors.service';
import { SystemNoteType } from '../../services/api/models';
import { AgreementsDto } from '../../services/api/models/agreements-dto';
import { SystemNote } from '../../services/api/models/system-note';
import { BaseService } from '../../services/api/services/base.service';
import { SystemNoteService } from '../../services/api/services/system-note.service';
import { UserService } from '../../services/api/services/user.service';
import { AuthService } from '../../services/auth/auth.service';
import { ErrorsService } from '../../services/errors-service/errors.service';
import { GeolocationService } from '../../services/geolocation/geolocation.service';
import {
  NavigateByLinkService,
  PossibleRedirections,
} from '../../services/navigate-by-link/navigate-by-link.service';
import { TranslationService } from '../../services/translations/translation.service';
import {
  UserDetailsService,
  WAF_HEADER_USER_EMAIL,
} from '../../services/user/user-details.service';
import {
  DATE_FORMAT_WITH_Z,
  EMAIL_REGEXP,
} from '../../services/utils/utils.service';
import { ReservedShortLinkService } from '../../services/reserved-services/reserved-short-link.service';
import { DATE_FORMAT } from '../../utils/string-utils';
import * as tz from 'moment-timezone';

export const LINK_PARAMS_KEY = 'linkParams';

enum loginViews {
  login = 'login',
  forgot = 'forgot',
  privacy = 'privacy',
  eula = 'eula',
}

@Component({
  selector: 'layout-auth',
  templateUrl: 'layout-auth.component.html',
  styleUrls: ['layout-auth.component.scss'],
})
export class LayoutAuthComponent implements OnInit, AfterViewInit, OnDestroy {
  public form: FormGroup;
  public formForgot: FormGroup;
  public loginView: loginViews = loginViews.login;
  public loginViewEnum: typeof loginViews = loginViews;
  public emailInputFocus: boolean = false;
  public isEmailSent: boolean = false;
  public emailInputForgotFocus: boolean = false;
  public passwordInputFocus: boolean = false;
  public passwordType: string = 'password';
  public agreements: AgreementsDto;
  public privacyAgreementText: string;
  public EULAAgreementText: string;
  public currentAppVersion: string = packageJson.version;
  public showAuthFail$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public isCustomerDeactivated: boolean = false;
  public currentNote: SystemNote[] = [];
  public isShowNote: boolean = false;

  private destroyed$ = new Subject();

  constructor(
    private baseService: BaseService,
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    private userDetailsService: UserDetailsService,
    private geolocationService: GeolocationService,
    private navigateByLinkService: NavigateByLinkService,
    public customerSelectorsService: CustomerSelectorsService,
    private route: ActivatedRoute,
    private httpClient: HttpClient,
    public translationService: TranslationService,
    private errorsService: ErrorsService,
    private systemNoteService: SystemNoteService,
    private reservedShortLinkService: ReservedShortLinkService
  ) {}

  public async ngOnInit() {
    this.getAgreements();
    this.translationService.languageChangeSubject
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.getAgreements();
      });

    this.form = new FormGroup({
      userName: new FormControl(null, [
        Validators.required,
        Validators.pattern(EMAIL_REGEXP),
      ]),
      password: new FormControl(null, [
        Validators.required,
        Validators.minLength(4),
      ]),
    });
    this.formForgot = new FormGroup({
      email: new FormControl(null, [
        Validators.required,
        Validators.pattern(EMAIL_REGEXP),
      ]),
    });
    this.systemNoteService
      .getActive()
      .pipe(take(1))
      .subscribe((res) => {
        res = _.orderBy(res, ['endDate', 'createDate'], ['asc', 'asc']);
        this.currentNote = res;
        if (this.currentNote.length > 0) {
          this.currentNote[0].startDate = moment
            .utc(this.currentNote[0].startDate)
            .format(DATE_FORMAT);

          this.currentNote[0].endDate = moment
            .utc(this.currentNote[0].endDate)
            .format(DATE_FORMAT);
        }
        this.isShowNote = true;
      });

    this.route.queryParams.subscribe((linkParams) => {
      const params = {
        login: linkParams.login,
        password: linkParams.password,
        redirectTo: linkParams.redirectTo,
        name: linkParams.name,
        type: linkParams.type,
        customerId: linkParams.customerId,
        facilityGroupId: linkParams.facilityGroupId,
        facilityId: linkParams.facilityId,
        dismissDays: linkParams.dismissDays,
        show: true,
        key: linkParams.key,
        link: linkParams.link,
        expired: false,
      };

      if (params.login && params.password) {
        this.loginWithLinkParams(params.login, params.password);
      }
      if (params.redirectTo) {
        this.saveLinkParamsAndNavigate(params);
      }
      if (params.link) {
        this.reservedShortLinkService
          .getFullLink({ linkUuid: params.link })
          .pipe(take(1))
          .subscribe((res) => {
            console.log('FROM BE: ', res);
            if (!!res) {
              window.location.replace(res);
            } else {
              params.show = false;
              params.expired = true;
              params.redirectTo = PossibleRedirections.dashboard;
              this.saveLinkParamsAndNavigate(params);
            }
          });
      }
    });
  }

  public saveLinkParamsAndNavigate(params) {
    sessionStorage.setItem(LINK_PARAMS_KEY, JSON.stringify(params));
    this.navigateByLinkService.navigateByLink(params);
  }

  public async loginWithLinkParams(login: string, password: string) {
    await this.authService.login(login, password);
    this.customerSelectorsService.resetService();
    await this.router.navigateByUrl('/');
    this.userDetailsService.setUserDetails();
    this.errorsService.isShowError$.next(false);
  }

  ngAfterViewInit() {
    console.log(
      'this.errorsService.isShowError$.value',
      this.errorsService.isShowError$.value
    );
    if (this.errorsService.isShowError$.value === true) {
      this.isCustomerDeactivated = true;
      this.showAuthFail$.next(true);
    }
  }

  public getAgreements() {
    this.userService
      .getAgreements({ language: this.translationService.selectedLanguage })
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        this.agreements = res;
        const privacyAgreementLink = res.Agreements.find(
          (item) => item.DocumentName === 'Privacy'
        ).Link;
        const EULAAgreementLink = res.Agreements.find(
          (item) => item.DocumentName === 'EULA'
        ).Link;
        this.httpClient
          .get(privacyAgreementLink, { responseType: 'text' })
          .pipe(take(1))
          .subscribe((res) => {
            this.privacyAgreementText = res;
          });
        this.httpClient
          .get(EULAAgreementLink, { responseType: 'text' })
          .pipe(take(1))
          .subscribe((res) => {
            this.EULAAgreementText = res;
          });
      });
  }

  public async submit() {
    if (this.form.invalid) {
      return;
    }

    const body = this.form.value;

    try {
      await this.authService.login(body.userName, body.password);
      this.customerSelectorsService.resetService();
      await this.router.navigateByUrl('/');
      this.userDetailsService.setUserDetails();
      localStorage.setItem(
        WAF_HEADER_USER_EMAIL,
        JSON.stringify(body.userName)
      );
      this.errorsService.isShowError$.next(false);
    } catch (e) {
      this.showErrorMessage(e);
    }
  }

  public showErrorMessage(error: HttpErrorResponse) {
    this.isCustomerDeactivated = false;
    this.showAuthFail$.next(true);
    return throwError(error);
  }

  public showHidePass(): void {
    if (this.passwordType === 'password') {
      this.passwordType = 'text';
    } else {
      this.passwordType = 'password';
    }
  }

  public forgotPassword(): void {
    if (this.formForgot.invalid) {
      return;
    }
    this.userService
      .forgotPassword({
        body: {
          email: this.formForgot.value.email,
        },
      })
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.isEmailSent = true;
        this.formForgot.reset();
      });
  }

  public get getLogo() {
    return this.geolocationService.getLogoPath();
  }

  public ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  protected readonly SystemNoteType = SystemNoteType;
  protected readonly moment = moment;
  protected readonly DATE_FORMAT = DATE_FORMAT;
  protected readonly DATE_FORMAT_WITH_Z = DATE_FORMAT_WITH_Z;
}

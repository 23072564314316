import { Injectable } from '@angular/core';
import { CustomerResponse } from '../api/models/customer-response';
import { FacilityGroupDto } from '../api/models/facility-group-dto';

@Injectable({ providedIn: 'root' })
export class CompanyDetailsService {
  private customerData: CustomerResponse;
  private customerFarms: FacilityGroupDto[] = [];

  constructor() {}

  public setCustomerFarms(ev: FacilityGroupDto[]) {
    this.customerFarms = ev;
  }

  public setCustomerData(ev: CustomerResponse) {
    this.customerData = ev;
  }

  public get customer(): CustomerResponse {
    return this.customerData;
  }

  public get farms(): FacilityGroupDto[] {
    return this.customerFarms;
  }
}

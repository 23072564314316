<div class="popup">
  <div class="popup__body">
    <div class="popup__body-header" *ngIf="!isComplete">
      {{ "EVENT.edit_event" | translate }}
    </div>
    <div class="popup__body-header" *ngIf="isComplete">
      {{ "EVENT.complete_event" | translate }}
    </div>
    <div class="popup__body-close">
      <span class="svg close-svg-blue" (click)="closeModal()"></span>
    </div>
    <div class="popup__body-content">
      <div class="menus__menu width-50">
        <div class="menus__menu-label">{{ "EVENT.select_event_type" | translate }}</div>
        <div class="menus__menu-selector">
          <div class="menus__menu-selector-label">{{ "EVENT.Type" | translate }}</div>
          <input disabled [value]="currentEvent.type">
        </div>
      </div>
      <div class="menus__menu-selector width-50" *ngIf="eventType === eventTypesEnum.AddVaccination">
        <div class="menus__menu-selector-label">{{ "EVENT.Country" | translate }}</div>
        <regular-select [value]="selectedCountry" (change)="countryChanged($event.target.value)">
          <option #option *ngFor="let item of countries"
                  [selected]="item === selectedCountry"
                  [value]="item">{{ item }}
          </option>
        </regular-select>
      </div>

      <!-- COMMON PART OF ALL EVENTS TYPE -->
      <div class="menus__menu width-100">
        <div class="menus__menu-label">{{ "EVENT.Event_Details" | translate }}</div>
        <div>
          <!--ADD FEED-->
          <ng-container *ngIf="eventType === eventTypesEnum.AddFeed">
            <feed-event
              [currentHarvest]="currentHarvest"
              [event]="currentEvent"
              [feed]="feed"
              [startDate]="startDate"
              [functionalMode]="eventFunctionalMode"
              (dateChanged)="changeSelectedDate($event)"
              (birdAgeChanged)="changeSelectedBirdAge($event)"
              (onCoccidiostatChanged)="currentEvent.values[1].value = $event"
              (onAdditivesChanged)="currentEvent.values[0].value = $event"
              (onRationChanged)="currentEvent.values[3].value = $event"
              (onMillChanged)="setMillValue($event, 0)"
              (onKgChanged)="currentEvent.values[4].value = $event"
              (onBatchNumberChanged)="currentEvent.values[5].value = $event"
            >
            </feed-event>
          </ng-container>


          <!--ADD MEDICATION-->
          <ng-container *ngIf="eventType === eventTypesEnum.AddMedication">
            <medication-event
              [startDate]="startDate"
              [currentHarvest]="currentHarvest"
              [event]="currentEvent"
              [medication]="medication"
              [functionalMode]="eventFunctionalMode"
              (dateChanged)="changeSelectedDate($event)"
              (birdAgeChanged)="changeSelectedBirdAge($event)"
              (onMedicationDurationChanged)="currentEvent.values[1].value = $event"
              (onmMedicationDiseaseChanged)="currentEvent.values[0].value = $event"
              (onAntibioticsChanged)="currentEvent.values[2].value = $event">
            </medication-event>
          </ng-container>

          <!--ADD MEDICATION-->
          <ng-container *ngIf="eventType === eventTypesEnum.AddThinning">
            <thinning-event
              [startDate]="startDate"
              [event]="currentEvent"
              [currentHarvest]="currentHarvest"
              [functionalMode]="eventFunctionalMode"
              [cropNumber]="cropNumber"
              (dateChanged)="changeSelectedDateTime($event)"
              (birdAgeChanged)="changeSelectedBirdAge($event)"
              (numberOfBirdsChanged)="changeThinningValues(currentEvent, $event, 'thinnedValue')"
              (totalKgChanged)="changeThinningValues(currentEvent, $event, 'totalKg')">
            </thinning-event>
          </ng-container>

          <!--ADD Nutraceutical-->
          <ng-container *ngIf="eventType === eventTypesEnum.AddNutraceutical">
            <nutraceutical-event
              [startDate]="startDate"
              [event]="currentEvent"
              [currentHarvest]="currentHarvest"
              [nutraceutical]="nutraceuticals"
              [functionalMode]="eventFunctionalMode"
              (dateChanged)="changeSelectedDate($event)"
              (birdAgeChanged)="changeSelectedBirdAge($event)"
              (onNutraceuticalChanged)="currentEvent.values[1].value = $event"
              (onReasonChanged)="currentEvent.values[2].value = $event"
              (onDurationChanged)="currentEvent.values[0].value = $event">
            </nutraceutical-event>
          </ng-container>

          <!--Add Vaccination-->
          <ng-container *ngIf="eventType === eventTypesEnum.AddVaccination">
            <vaccination-event
              [startDate]="startDate"
              [event]="currentEvent"
              [functionalMode]="eventFunctionalMode"
              [currentHarvest]="currentHarvest"
              [vaccination]="vaccination[selectedCountry]"
              (dateChanged)="changeSelectedDate($event)"
              (birdAgeChanged)="changeSelectedBirdAge($event)"
              (onTypeChanged)="currentEvent.values[1].value = $event"
              (onDiseaseChanged)="currentEvent.values[0].value = $event"
              (onVaccineChanged)="currentEvent.values[2].value = $event">
            </vaccination-event>
          </ng-container>

          <!--   Flush water line       -->
          <ng-container *ngIf="eventType === eventTypesEnum.FlushWaterLine">
            <flush-water-line
              [startDate]="startDate"
              [event]="currentEvent"
              [harvestEvents]="allEvents"
              [functionalMode]="eventFunctionalMode"
              [currentHarvest]="currentHarvest"
              (onCommentChanged)="currentEvent.values[0].value = $event"
              (recurrentIntevalChanged)="currentEvent.values[5].value = $event"
              (dateChanged)="changeSelectedDate($event)"
              (endDateChanged)="changeRecurrentEndDate($event)"
              (birdAgeChanged)="changeSelectedBirdAge($event)"
              (recurrentEndBirdAgeChanged)="changeRecurrentEndBirdAge($event)"
              [allEvents]="allEvents"
            >
            </flush-water-line>
          </ng-container>
          <!--  Water Sanitation      -->
          <ng-container *ngIf="eventType === eventTypesEnum.WaterSanitation">
            <water-sanitation
              [startDate]="startDate"
              [event]="currentEvent"
              [functionalMode]="eventFunctionalMode"
              [currentHarvest]="currentHarvest"
              (onCommentChanged)="currentEvent.values[0].value = $event"
              (dateChanged)="changeSelectedDate($event)"
              (birdAgeChanged)="changeSelectedBirdAge($event)"
            >
            </water-sanitation>
          </ng-container>
          <!-- Planned thinning ages      -->
          <ng-container *ngIf="eventType === eventTypesEnum.PlannedThinningAges">
            <planned-thinning-ages
              [startDate]="startDate"
              [event]="currentEvent"
              [functionalMode]="eventFunctionalMode"
              [currentHarvest]="currentHarvest"
              (dateChanged)="changeSelectedDate($event)"
              (birdAgeChanged)="changeSelectedBirdAge($event)"
            >
            </planned-thinning-ages>
          </ng-container>
          <!-- Planned clearing age      -->
          <ng-container *ngIf="eventType === eventTypesEnum.PlannedClearingAge">
            <planned-clearing-age
              [startDate]="startDate"
              [event]="currentEvent"
              [functionalMode]="eventFunctionalMode"
              [currentHarvest]="currentHarvest"
              (onCommentChanged)="currentEvent.values[0].value = $event"
              (dateChanged)="changeSelectedDate($event)"
              (birdAgeChanged)="changeSelectedBirdAge($event)"
            >
            </planned-clearing-age>
          </ng-container>
          <!-- Planned hand weighing of birds      -->
          <ng-container *ngIf="eventType === eventTypesEnum.PlannedHandWeighingOfBirds">
            <planned-hand-weight
              [startDate]="startDate"
              [event]="currentEvent"
              [currentHarvest]="currentHarvest"
              [functionalMode]="eventFunctionalMode"
              (onCommentChanged)="currentEvent.values[0].value = $event"
              (onAvWeightChanged)="currentEvent.values[1].value = $event"
              (dateChanged)="changeSelectedDate($event)"
              (birdAgeChanged)="changeSelectedBirdAge($event)"
            >
            </planned-hand-weight>
          </ng-container>
          <!-- Planned footpad scoring      -->
          <ng-container *ngIf="eventType === eventTypesEnum.PlannedFootpadScoringOfBirds">
            <planned-footpad-scoring
              [startDate]="startDate"
              [event]="currentEvent"
              [currentHarvest]="currentHarvest"
              [functionalMode]="eventFunctionalMode"
              (score0)="currentEvent.values[0].value = $event"
              (score1)="currentEvent.values[1].value = $event"
              (score2)="currentEvent.values[2].value = $event"
              (numberOfZone)="setSelectedZones($event)"
              (dateChanged)="changeSelectedDate($event)"
              (birdAgeChanged)="changeSelectedBirdAge($event)"
            >
            </planned-footpad-scoring>
          </ng-container>
          <ng-container *ngIf="eventType === eventTypesEnum.Brooding">
            <brooding-event
              [startDate]="startDate"
              [currentHarvest]="currentHarvest"
              [event]="currentEvent"
              [currentEvents]="allHarvestEvents"
              [rowIndex]="currentEventIndex"
              [functionalMode]="eventFunctionalMode"
              [possibleDeleteFirst]="true"
              (selectedZonesChanged)="currentEvent.values[0].value = $event"
              (durationChanged)="setSelectedDurationAndUpdateInBroodingService($event)"
              (dateChanged)="changeSelectedDate($event)"
              (birdAgeChanged)="changeSelectedBirdAge($event)"
            >
            </brooding-event>
          </ng-container>
          <!--   Other Events       -->
          <ng-container *ngIf="eventType === eventTypesEnum.OtherEvents">
            <other-event
              [startDate]="startDate"
              [event]="currentEvent"
              [functionalMode]="eventFunctionalMode"
              [currentHarvest]="currentHarvest"
              (onCommentChanged)="currentEvent.values[0].value = $event"
              (recurrentIntevalChanged)="currentEvent.values[5].value = $event"
              (dateChanged)="changeSelectedDate($event)"
              (endDateChanged)="changeRecurrentEndDate($event)"
              (birdAgeChanged)="changeSelectedBirdAge($event)"
              (recurrentEndBirdAgeChanged)="changeRecurrentEndBirdAge($event)"
            >
            </other-event>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="center width-100">
      <button class="blue-rounded-button new-farm-button"
              (click)="closeModal()">{{ 'COMMON.BUTTONS.CANCEL' | translate }}
      </button>
      <button class="blue-rounded-button new-farm-button"
              (click)="updateEvent()" *ngIf="!isComplete"
              [disabled]="eventType === eventTypesEnum.Brooding && currentEvent.values[1].value === '0'">{{ 'COMMON.BUTTONS.Update' | translate }}
      </button>
      <button class="blue-rounded-button new-farm-button"
              (click)="updateEvent()" *ngIf="isComplete">{{ 'COMMON.BUTTONS.Complete' | translate }}
      </button>
    </div>
  </div>
</div>

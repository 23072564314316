import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CustomerSelectorsService } from '../../../../services/customer-selectors/customer-selectors.service';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { HarvestFullResponse } from '../../../../services/api/models/harvest-full-response';
import { HarvestService } from '../../../../services/api/services/harvest.service';
import { take, takeUntil } from 'rxjs/operators';
import { HarvestEventDto } from '../../../../services/api/models/harvest-event-dto';
import { LookupTableService } from '../../../../services/api/services/lookup-table.service';
import { HarvestEvent } from '../../../../services/api/models/harvest-event';
import { PermissionService } from '../../../../services/user/permission-service';
import { PermissionName } from '../../../../services/api/models/permission-name';
import { EventFunctionalModes } from '../events.component';
import { CurrentCropBehaviourService } from '../../../../services/harvest/current-crop-behaviour.service';
import { BroodingService } from '../../../../services/brooding/brooding.service';
import { HarvestEventsLookupService } from '../../../../services/api/services/harvest-events-lookup.service';
import { VaccinationTitle } from '../../../../services/api/models/vaccination-title';
import { VaccinationDto } from '../../../../services/api/models/vaccination-dto';
import { HarvestCustomerDefaultsDto } from '../../../../services/api/models/harvest-customer-defaults-dto';
import {
  DATE_FORMAT,
  EVENT_DATE_FORMAT,
  TIME_ONLY,
} from '../../../../utils/string-utils';
import { FacilityDataDto } from '../../../../services/api/models/facility-data-dto';
import { FacilityGroupService } from '../../../../services/api/services/facility-group.service';
import * as _ from 'lodash';
import { INotificationContent } from '../../modals/notification/notification.component';

export enum EventType {
  AddFeed = 'Add Feed',
  AddVaccination = 'Add Vaccination',
  AddMedication = 'Add Medication',
  AddThinning = 'Add Thinning',
  AddNutraceutical = 'Add Nutraceutical',
  FlushWaterLine = 'Flush Water Line',
  WaterSanitation = 'Water Sanitation',
  PlannedThinningAges = 'Planned Thinning Ages',
  PlannedClearingAge = 'Planned Clearing Age',
  PlannedHandWeighingOfBirds = 'Planned Hand Weighing of Birds',
  PlannedFootpadScoringOfBirds = 'Planned Footpad Scoring of Birds',
  Brooding = 'Brooding',
  OtherEvents = 'Other Events',
}

export interface IExtendedHarvestEventDto extends HarvestEventDto {
  birdAge?: number;
}

@Component({
  selector: 'add-event-modal',
  templateUrl: 'add-event-modal.component.html',
  styleUrls: ['add-event-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddEventModalComponent implements OnInit {
  @Output() public onCloseModal: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() public onEventCreated: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() public onError: EventEmitter<INotificationContent> =
    new EventEmitter<INotificationContent>();
  @Output() public onNotification: EventEmitter<INotificationContent> =
    new EventEmitter<INotificationContent>();
  @Input() public currentHarvest: HarvestFullResponse;
  @Input() public allEvents: HarvestEvent[];
  public previousCropEvents: HarvestEvent[];
  public previousCropStartDate: string;
  public prepopulatedEvent: HarvestEvent;
  public nowDate: Date;
  public selectDate: string;
  public coccidiostat = [];
  public additives = [];
  public mill = [];
  public isFlushingRecurrentEndDateOutOfRange = false;
  public disabledAfterClick = false;
  public ration = [];
  public medicationDisease = [];
  public vaccinationDisease = [];
  public medicationDuration = [];
  public antibiotics = [];
  public nutraceuticalDuration = [];
  public nutraceutical = [];
  public reason = [];
  public types = [];
  public vaccine = [];
  public eventType: EventType = EventType.AddFeed;
  public eventTypesEnum: typeof EventType = EventType;
  public harvestEvents: IExtendedHarvestEventDto[] = [];
  public allHarvestEvents: HarvestEvent[] = [];
  public selectedAdditive: string;
  public selectedCoccidiostat: string;
  public selectedMill: string;
  public selectedRation: string;
  public selectedNutraceuticalDuration: string;
  public selectedNutraceutical: string;
  public selectedReason: string;
  public selectedMedicationDisease: string;
  public selectedMedicationDuration: string;
  public selectedMedication: string;
  public selectedVaccinationDisease: string;
  public selectedType: string;
  public selectedVaccine: string;
  public startDate: Date;
  public maxDate: Date;
  public birdAge: number = null;
  public selectedCountry: string;
  public countries = [];
  public vaccination;
  public hatcheryVaccineList = [];
  public isShowBeforeCloseModal: boolean = false;
  public isTouched: boolean = false;
  public isDefaultCountry: boolean = false;
  public feed;
  public medication;
  public nutraceuticals;
  public hatcheryVaccination;
  public defaults: HarvestCustomerDefaultsDto;
  public eventFunctionalMode = EventFunctionalModes.addEvent;

  public crops = [];

  public houses: FacilityDataDto[];
  public originalHouses: FacilityDataDto[];
  public selectedHouseIds = [];

  public myFarmCropCardViewEvents: PermissionName =
    PermissionName.MyFarmCropCardViewEvents;
  public myFarmCropCardAddBrooding: PermissionName =
    PermissionName.MyFarmCropCardAddBrooding;

  private destroyed$ = new Subject();

  public errorContent: INotificationContent = {
    color: null,
    fontColor: null,
    message: null,
    IconName: null,
    name: null,
  };

  public notificationContent: INotificationContent = {
    color: null,
    fontColor: null,
    message: null,
    IconName: null,
  };

  @ViewChild('popupBody')
  public popupBody: any;

  constructor(
    public customerSelectorsService: CustomerSelectorsService,
    public cropBehaviourService: CurrentCropBehaviourService,
    public broodingService: BroodingService,
    private harvestService: HarvestService,
    private lookupTableService: LookupTableService,
    private harvestEventsLookupService: HarvestEventsLookupService,
    public readonly permissionService: PermissionService,
    public facilityGroupService: FacilityGroupService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  public ngOnInit() {
    this.nowDate = moment().toDate();
    this.harvestService
      .getDefaults({
        customerId: this.customerSelectorsService.customerId,
      })
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        this.defaults = res;
        this.lookupTableService
          .getHarvestEventsMap_1()
          .pipe(take(1))
          .subscribe(async (lookupTableres) => {
            await this.getFacilityData();
            this.leaveOnlyDefaultVaccines(lookupTableres);
            this.changeDetectorRef.detectChanges();
          });
      });

    this.nowDate = moment().toDate();
  }

  public showErrorModal(errMessage: string, isNamed: boolean = false) {
    if (errMessage === 'thinnedValue should be more then 0') {
      this.setErrorContent('COMMON.ERRORS.Thinned_value_should_be_more_then_0');
    } else if (errMessage === 'thinnedValue is mandatory') {
      this.setErrorContent('COMMON.ERRORS.Thinned_value_is_mandatory');
    } else if (errMessage === 'addEvent.event[0].date: must not be null') {
      this.setErrorContent('COMMON.ERRORS.Date_is_mandatory');
    } else {
      if (isNamed) {
        this.setErrorContent('COMMON.ERRORS.Event_creation_error', errMessage);
      } else {
        this.setErrorContent(errMessage);
      }
    }
  }

  public showNotificationModal() {
    this.setNotificationContent();
  }

  public findHouseNames(errMessages) {
    let errMessage = '';
    errMessages.forEach((err) => {
      errMessage += err.split('houseName=')[1] + ', ';
    });

    return errMessage.slice(0, -2);
  }

  public setErrorContent(errMessage: string, name?: string) {
    this.errorContent.message = errMessage;
    this.errorContent.color = '#ffbbbb';
    this.errorContent.fontColor = '#ad120d';
    this.errorContent.IconName = 'error';

    if (name) {
      this.errorContent.name = name;
    }
    this.changeDetectorRef.detectChanges();
  }

  public get calculateHeight() {
    if (this.eventType === this.eventTypesEnum.AddVaccination) {
      return '167px';
    } else {
      return '238px';
    }
  }

  public setNotificationContent() {
    this.notificationContent.message = 'COMMON.NOTIFICATION.Event_created';
    this.notificationContent.color = '#dff8d9';
    this.notificationContent.fontColor = '#007406';
    this.notificationContent.IconName = 'notification';
    this.changeDetectorRef.detectChanges();
  }

  public setSelectedHouses(houses) {
    this.selectedHouseIds = houses;

    this.setMaxDate();
    this.setMinDate();
  }

  public get getCurrentHouse() {
    return this.houses?.find(
      (house) =>
        house.facilityId === this.customerSelectorsService.currentHouse?.id
    );
  }

  public setMaxDate() {
    const closeDates = this.crops
      ?.filter((crop) => this.selectedHouseIds?.includes(crop.house.facilityId))
      ?.map((crop) => crop.latestCrop.closeDate)
      .filter((date) => date);

    const maxDate =
      closeDates.filter((date) => date)?.length > 0
        ? closeDates.reduce((a, b) => {
            return new Date(a) < new Date(b) ? new Date(a) : new Date(b);
          }, undefined)
        : undefined;

    this.maxDate = maxDate
      ? moment(maxDate)
          .set({
            hour: +moment(maxDate).format('HH'),
            minute: +moment(maxDate).format('mm'),
          })
          .toDate()
      : undefined;
  }

  public setMinDate() {
    const startDates = this.crops
      ?.filter((crop) => this.selectedHouseIds?.includes(crop.house.facilityId))
      ?.map((crop) => crop.latestCrop.startDate);

    const minDate =
      startDates.filter((date) => date)?.length > 0
        ? startDates.reduce((a, b) => {
            return new Date(a) > new Date(b) ? new Date(a) : new Date(b);
          }, undefined)
        : undefined;
    if (this.startDate?.toString() !== minDate?.toString()) {
      this.startDate = minDate
        ? moment(minDate)
            .set({
              hour: +moment(minDate).format('HH'),
              minute: +moment(minDate).format('mm'),
            })
            .toDate()
        : undefined;
    }
    this.birdAge = moment(this.maxDate ? this.maxDate : this.nowDate).diff(
      this.startDate,
      'days'
    );

    this.selectDate = this.maxDate
      ? moment(this.maxDate)
          .set({
            hour: +moment(this.startDate).format('HH'),
            minute: +moment(this.startDate).format('mm'),
          })
          .format(EVENT_DATE_FORMAT)
      : moment(this.nowDate)
          .set({
            hour: +moment(this.startDate).format('HH'),
            minute: +moment(this.startDate).format('mm'),
          })
          .format(EVENT_DATE_FORMAT);
  }

  public async getFacilityData() {
    if (!!this.customerSelectorsService.farmId) {
      this.facilityGroupService
        .getFacilityData({
          facilityGroupId: this.customerSelectorsService.farmId,
        })
        .pipe(take(1))
        .subscribe((res) => {
          this.houses = res;
          this.originalHouses = _.cloneDeep(this.houses);
          this.crops = this.houses.map((house) => {
            const currentHouseCrops =
              this.customerSelectorsService.housesList.find(
                (h) => h.id === house.facilityId
              ).crops;

            console.log(this.customerSelectorsService.housesList);
            const latestCrop = currentHouseCrops
              ? currentHouseCrops.find(
                  (crop) => crop.closeDate === undefined
                ) ||
                currentHouseCrops.reduce((prev, current) => {
                  return prev && prev.closeDate > current.closeDate
                    ? prev
                    : current;
                })
              : null;

            return {
              house: house,
              latestCrop: latestCrop,
            };
          });
        });
    }
  }

  public leaveOnlyDefaultVaccines(lookupTableres) {
    const defaultVaccines = this.defaults.vaccines;
    console.log('DEFAULT VACCINES', defaultVaccines);
    const hatcheryVaccines = defaultVaccines.filter(
      (item) => item.title === VaccinationTitle.HatcheryVaccine
    );
    const vaccines = defaultVaccines.filter(
      (item) => item.title === VaccinationTitle.VaccineG
    );
    lookupTableres.VACCINE_G = this.convertMapToObject(vaccines);
    lookupTableres.HATCHERY_VACCINE = this.convertMapToObject(hatcheryVaccines);

    this.setDropdownValues(lookupTableres);
  }

  public convertMapToObject(vaccines: VaccinationDto[]) {
    let allTypesList: string[] = [
      ...new Set(vaccines.map((item) => item.type)),
    ];

    let vaccinesObj = {};

    vaccines.forEach((vaccine) => {
      vaccinesObj[vaccine.country] = {};

      const typesArrayOfArrays = [];
      allTypesList.forEach((type) => typesArrayOfArrays.push([type, null]));
      vaccinesObj[vaccine.country] = Object.fromEntries(typesArrayOfArrays);

      Object.keys(vaccinesObj[vaccine.country]).forEach((type) => {
        if (vaccinesObj[vaccine.country][type] === null) {
          let diseasesArrayOfArrays = [];
          let diseasePerType = [
            ...new Set(
              vaccines.map((vaccine1) => {
                if (vaccine1.type === type) {
                  return vaccine1.disease;
                }
              })
            ),
          ];

          diseasePerType = diseasePerType.filter((el) => {
            return el !== undefined;
          });

          diseasePerType.forEach((disease) =>
            diseasesArrayOfArrays.push([disease, []])
          );
          vaccinesObj[vaccine.country][type] = Object.fromEntries(
            diseasesArrayOfArrays
          );
        }
      });

      Object.keys(vaccinesObj[vaccine.country]).forEach((type) => {
        Object.keys(vaccinesObj[vaccine.country][type]).forEach((disease) => {
          let filteredVaccines = vaccines.map((item) => {
            if (item.type === type && item.disease === disease) {
              return item.vaccine;
            }
          });
          filteredVaccines = filteredVaccines.filter((el) => {
            return el !== undefined;
          });
          vaccinesObj[vaccine.country][type][disease] = filteredVaccines;
        });
      });
    });

    console.log('vaccinesObj', vaccinesObj);
    return vaccinesObj;
  }

  public setDropdownValues(res) {
    const feed = res?.FEED;
    const medication = res?.MEDICATION;
    const nutraceutical = res?.NUTRACEUTICAL;
    this.vaccination = res?.VACCINE_G;
    this.hatcheryVaccineList = res?.HATCHERY_VACCINE;
    this.countries = Object.keys(this.vaccination);
    this.feed = res?.FEED;
    this.medication = res?.MEDICATION;
    this.nutraceuticals = res?.NUTRACEUTICAL;
    this.hatcheryVaccination = res?.HATCHERY_VACCINE;

    // @ts-ignore
    this.coccidiostat = feed?.Coccidiostat;
    this.coccidiostat.push('Other');
    this.coccidiostat.unshift('None');
    this.additives = feed?.Additives;
    this.additives.push('Other');
    this.additives.unshift('None');
    this.ration = feed?.Ration;
    this.mill = feed?.Mill;
    this.medicationDisease = medication?.Disease;
    this.medicationDisease.push('Other');
    this.medicationDuration = medication?.Duration;
    this.medicationDuration.push('Other');
    this.antibiotics = medication?.Antibiotics;
    this.antibiotics.push('Other');
    this.nutraceuticalDuration = nutraceutical?.Duration;
    this.nutraceutical = nutraceutical?.Nutraceutical;
    this.nutraceutical.push('Other');
    this.reason = nutraceutical?.Reason;
    this.findDefaultEvent();

    this.selectedAdditive = this.additives[0];
    this.selectedCoccidiostat = this.coccidiostat[0];
    this.selectedMill = this.mill[0];
    this.selectedRation = this.ration[0];
    this.selectedMedicationDisease = this.medicationDisease[0];
    this.selectedMedicationDuration = this.medicationDuration[0];
    this.selectedMedication = this.antibiotics[0];
    this.selectedNutraceuticalDuration = this.nutraceuticalDuration[0];
    this.selectedNutraceutical = this.nutraceutical[0];
    this.selectedReason = this.reason[0];

    this.getAllHarvestEvents();
    this.findDefaultCountry();
    this.setMaxDate();
    this.setMinDate();
    this.addEvent();
    this.changeDetectorRef.detectChanges();
  }

  public getAllHarvestEvents() {
    if (this.permissionService.hasPermission(this.myFarmCropCardViewEvents)) {
      this.harvestService
        .getEvents({
          harvestId: this.customerSelectorsService.currentCrop.id,
        })
        .pipe(take(1))
        .subscribe((res) => {
          this.allHarvestEvents = res;
          this.cropBehaviourService.isBroodingActive =
            this.allHarvestEvents.some(
              (item) => item.type === EventType.Brooding
            );
          this.broodingService.setBroodingDurationRemaining(
            this.allHarvestEvents.filter(
              (item) => item.type === EventType.Brooding
            )
          );
          this.changeDetectorRef.detectChanges();
        });
    }
  }

  public changeThinningValues(
    event: IExtendedHarvestEventDto,
    value: string,
    prop: string
  ) {
    event.values.find((v) => v.name === prop).value = value;
  }

  public findDefaultCountry() {
    const currentFarmCountry =
      this.customerSelectorsService.currentFarm.country;
    const currentFarmCountryIndex = this.countries.indexOf(currentFarmCountry);
    const currentCustomerCountry =
      this.customerSelectorsService.currentCustomer.country;
    const currentCustomerCountryIndex = this.countries.indexOf(
      currentCustomerCountry
    );
    if (currentFarmCountryIndex >= 0) {
      this.isDefaultCountry = false;
      this.selectedCountry = this.countries[currentFarmCountryIndex];
    } else if (currentCustomerCountryIndex >= 0) {
      this.isDefaultCountry = false;
      this.selectedCountry = this.countries[currentCustomerCountryIndex];
    } else {
      this.isDefaultCountry = true;
      const unitedKingdomIdx = this.countries.indexOf('United Kingdom');
      this.selectedCountry = this.countries[unitedKingdomIdx];
    }
    console.log('currentFarmCountry', currentFarmCountry);
    console.log('currentCustomerCountry', currentCustomerCountry);
    console.log('selectedCountry', this.selectedCountry);
    this.changeDetectorRef.detectChanges();
  }

  public findDefaultEvent() {
    if (this.customerSelectorsService.cropsList.length > 1) {
      console.log('HAS PREVIOUS CROP');
      this.getPrevCropEvents();
    } else {
      this.harvestService
        .getFacilityGroupEvents({
          facilityGroupId: this.customerSelectorsService.farmId,
        })
        .subscribe((res) => {
          this.previousCropEvents = res;
          console.log('previousCropEvents', this.previousCropEvents);
          this.setPopulatedValues();
        });
    }
    this.changeDetectorRef.detectChanges();
  }

  public getPrevCropEvents() {
    console.log('getPrevCropIdx', this.getPrevCropIdx());
    if (this.permissionService.hasPermission(this.myFarmCropCardViewEvents)) {
      this.harvestService
        .getEvents({
          harvestId:
            this.customerSelectorsService.cropsList[this.getPrevCropIdx()].id,
        })
        .pipe(take(1))
        .subscribe((res) => {
          this.previousCropStartDate =
            this.customerSelectorsService.cropsList[
              this.getPrevCropIdx()
            ].startDate;
          this.previousCropEvents = res;
          console.log('previousCropEvents', this.previousCropEvents);
          this.setPopulatedValues();
          this.changeDetectorRef.detectChanges();
        });
    }
  }

  public getPrevCropIdx() {
    console.log('cropsList', this.customerSelectorsService.cropsList);
    const currentCropIdx = this.customerSelectorsService.currentCrop$.value.idx;
    let prevCropListIdx = this.customerSelectorsService.cropsList.findIndex(
      (item) => {
        return item.idx === currentCropIdx - 1;
      }
    );
    if (prevCropListIdx < 0) {
      prevCropListIdx = this.customerSelectorsService.cropsList.length - 1;
    }
    console.log('prevCropListIdx', prevCropListIdx);
    return prevCropListIdx;
  }

  public setPopulatedValues() {
    console.log('eventType', this.eventType);
    const eventsByType = this.previousCropEvents.filter(
      (item) => item.type === this.eventType
    );
    console.log('eventsByType', eventsByType);
    const birdAges = eventsByType.map((item) => item.birdAge);
    console.log('birdAges', birdAges);
    let closestBirdAge: number;
    if (birdAges.length > 0) {
      closestBirdAge = birdAges.reduce((prev, curr) => {
        return Math.abs(curr - this.birdAge) < Math.abs(prev - this.birdAge)
          ? curr
          : prev;
      });
      console.log('closestBirdAge', closestBirdAge);
    }

    this.prepopulatedEvent = eventsByType.find(
      (item) => item.birdAge === closestBirdAge
    );

    if (!!this.prepopulatedEvent) {
      switch (this.eventType) {
        case EventType.AddFeed:
          console.log('prepopulatedEvent', this.prepopulatedEvent);
          this.harvestEvents[0].values[1].value =
            this.prepopulatedEvent.values[1]?.value;
          this.harvestEvents[0].values[0].value =
            this.prepopulatedEvent.values[0]?.value;
          this.harvestEvents[0].values[3].value =
            this.prepopulatedEvent.values[3]?.value;
          this.harvestEvents[0].values[2].value =
            this.prepopulatedEvent.values[2]?.value;
          this.harvestEvents[0].values[4].value =
            this.prepopulatedEvent.values[4]?.value;
          this.harvestEvents[0].values[5].value =
            this.prepopulatedEvent.values[5]?.value;
          this.harvestEvents = JSON.parse(JSON.stringify(this.harvestEvents));
          break;
        case EventType.AddMedication:
          console.log('prepopulatedEvent', this.prepopulatedEvent);

          this.selectedMedicationDuration =
            this.prepopulatedEvent.values[1]?.value;
          this.harvestEvents[0].values[1].value =
            this.selectedMedicationDuration;
          this.selectedMedicationDisease =
            this.prepopulatedEvent.values[0]?.value;
          this.harvestEvents[0].values[0].value =
            this.selectedMedicationDisease;
          this.selectedMedication = this.prepopulatedEvent.values[2]?.value;
          this.harvestEvents[0].values[2].value = this.selectedMedication;
          this.harvestEvents = JSON.parse(JSON.stringify(this.harvestEvents));
          break;
        case EventType.AddThinning:
          console.log('prepopulatedEvent', this.prepopulatedEvent);

          this.harvestEvents = JSON.parse(JSON.stringify(this.harvestEvents));
          break;
        case EventType.AddNutraceutical:
          console.log('prepopulatedEvent', this.prepopulatedEvent);

          this.harvestEvents[0].values[1].value =
            this.prepopulatedEvent.values[1]?.value;
          this.harvestEvents[0].values[2].value =
            this.prepopulatedEvent.values[2]?.value;
          this.harvestEvents[0].values[0].value =
            this.prepopulatedEvent.values[0]?.value;
          this.harvestEvents = JSON.parse(JSON.stringify(this.harvestEvents));
          break;
        case EventType.AddVaccination:
          console.log('prepopulatedEvent', this.prepopulatedEvent);

          if (this.isDefaultCountry) {
            this.harvestEvents[0].values[1].value = this.types[0];
            this.harvestEvents[0].values[0].value = this.vaccinationDisease[0];
            this.harvestEvents[0].values[2].value = this.vaccine[0];
            this.harvestEvents = JSON.parse(JSON.stringify(this.harvestEvents));
          } else {
            this.harvestEvents[0].values[1].value =
              this.prepopulatedEvent.values[1]?.value;
            this.harvestEvents[0].values[0].value =
              this.prepopulatedEvent.values[0]?.value;
            this.harvestEvents[0].values[2].value =
              this.prepopulatedEvent.values[2]?.value;
            this.harvestEvents = JSON.parse(JSON.stringify(this.harvestEvents));
          }
          break;
      }
    }
    this.changeDetectorRef.detectChanges();
  }

  public setSelectedDurationAndUpdateInBroodingService(value, idx?) {
    this.harvestEvents[idx].values[1].value = value;
    this.changeDetectorRef.detectChanges();
  }

  public closeModal() {
    this.onCloseModal.emit(true);
  }

  public confirmBeforeClose() {
    if (this.isTouched && this.isFlushingRecurrentEndDateOutOfRange === false) {
      this.isShowBeforeCloseModal = true;
      this.changeDetectorRef.detectChanges();
    } else {
      this.closeModal();
      this.changeDetectorRef.detectChanges();
    }
  }

  public changeSelectedDateTime(value, i) {
    this.selectDate = value;
    this.harvestEvents[i].date = this.selectDate;
    this.changeDetectorRef.detectChanges();
  }

  public changeSelectedDate(date: Date, i: number) {
    this.isTouched = true;
    let startDateTime = moment(date).format(TIME_ONLY);
    if (
      moment(date).format(EVENT_DATE_FORMAT) !==
      moment(new Date()).format(EVENT_DATE_FORMAT)
    ) {
      startDateTime = moment(this.currentHarvest.startDate).format(TIME_ONLY);
    }

    let stringDate = moment(date).format(EVENT_DATE_FORMAT);
    let dateStringWithSelectedTime =
      stringDate.split('').slice(0, 11).join('') + startDateTime;
    console.log('dateStringWithSelectedTime', dateStringWithSelectedTime);

    this.harvestEvents[i].date = dateStringWithSelectedTime;
    this.harvestEvents[i].birdAge = null;

    if (
      this.harvestEvents[0].type === EventType.FlushWaterLine &&
      this.harvestEvents[i].values[this.idByParameterName('isRecurring', i)]
        ?.value === 'true'
    ) {
      this.harvestEvents[i].values[
        this.idByParameterName('startDate', i)
      ].value = dateStringWithSelectedTime;
      this.harvestEvents[i].values[
        this.idByParameterName('startBirdAge', i)
      ].value = null;
    }

    if (
      this.harvestEvents[0].type === EventType.OtherEvents &&
      this.harvestEvents[i].values[this.idByParameterName('isRecurring', i)]
        ?.value === 'true'
    ) {
      this.harvestEvents[i].values[
        this.idByParameterName('startDate', i)
      ].value = dateStringWithSelectedTime;
      this.harvestEvents[i].values[
        this.idByParameterName('startBirdAge', i)
      ].value = null;
    }
    this.changeDetectorRef.detectChanges();
    console.log('SelectedDate', this.harvestEvents[i].date);
  }

  public idByParameterName(name: string, i) {
    return this.harvestEvents[i].values.findIndex((value) => {
      return value.name === name;
    });
  }

  public changeRecurrentEndDate(date: Date, i: number) {
    this.isTouched = true;
    let startDateTime = moment(date).format(TIME_ONLY);
    if (
      moment(date).format(EVENT_DATE_FORMAT) !==
      moment(new Date()).format(EVENT_DATE_FORMAT)
    ) {
      startDateTime = moment(this.currentHarvest.startDate).format(TIME_ONLY);
    }

    let stringDate = moment(date).format(EVENT_DATE_FORMAT);
    let dateStringWithSelectedTime =
      stringDate.split('').slice(0, 11).join('') + startDateTime;
    console.log('dateStringWithSelectedTime', dateStringWithSelectedTime);
    if (this.harvestEvents[0].type === EventType.FlushWaterLine) {
      this.harvestEvents[i].values[this.idByParameterName('endDate', i)].value =
        dateStringWithSelectedTime;
      this.harvestEvents[i].values[
        this.idByParameterName('endBirdAge', i)
      ].value = null;
    } else if (this.harvestEvents[0].type === EventType.OtherEvents) {
      this.harvestEvents[i].values[this.idByParameterName('endDate', i)].value =
        dateStringWithSelectedTime;
      this.harvestEvents[i].values[
        this.idByParameterName('endBirdAge', i)
      ].value = null;
    }

    console.log(
      'changeRecurrentEndDate',
      this.harvestEvents[i].values[this.idByParameterName('endDate', i)].value
    );
    this.changeDetectorRef.detectChanges();
  }

  public changeSelectedBirdAge(birdAge: number, i: number) {
    this.isTouched = true;

    this.harvestEvents[i].date = null;
    this.harvestEvents[i].birdAge = birdAge;
    // =================FLUSH=====================
    if (
      this.harvestEvents[0].type === EventType.FlushWaterLine &&
      this.harvestEvents[i].values[this.idByParameterName('startDate', i)]
        ?.value
    ) {
      this.harvestEvents[i].values[
        this.idByParameterName('startDate', i)
      ].value = null;
    }

    if (
      this.harvestEvents[0].type === EventType.FlushWaterLine &&
      this.harvestEvents[i].values[this.idByParameterName('isRecurring', i)]
        ?.value === 'true'
    ) {
      this.harvestEvents[i].values[
        this.idByParameterName('startBirdAge', i)
      ].value = birdAge.toString();
    }
    // =====================OTHER===========================
    if (
      this.harvestEvents[0].type === EventType.OtherEvents &&
      this.harvestEvents[i].values[this.idByParameterName('startDate', i)]
        ?.value
    ) {
      this.harvestEvents[i].values[
        this.idByParameterName('startDate', i)
      ].value = null;
    }
    if (
      this.harvestEvents[0].type === EventType.OtherEvents &&
      this.harvestEvents[i].values[this.idByParameterName('isRecurring', i)]
        ?.value === 'true'
    ) {
      this.harvestEvents[i].values[
        this.idByParameterName('startBirdAge', i)
      ].value = birdAge.toString();
    }

    console.log('event after changes birdAge', this.harvestEvents[i]);
    this.changeDetectorRef.detectChanges();
  }

  public changeRecurrentEndBirdAge(birdAge: number, i: number) {
    this.isTouched = true;
    if (this.harvestEvents[0].type === EventType.FlushWaterLine) {
      this.harvestEvents[i].values[this.idByParameterName('endDate', i)].value =
        null;
      this.harvestEvents[i].values[
        this.idByParameterName('endBirdAge', i)
      ].value = birdAge.toString();
    } else if (this.harvestEvents[0].type === EventType.OtherEvents) {
      this.harvestEvents[i].values[this.idByParameterName('endDate', i)].value =
        null;
      this.harvestEvents[i].values[
        this.idByParameterName('endBirdAge', i)
      ].value = birdAge.toString();
    }
    console.log('event after changes EndBirdAge', this.harvestEvents[i]);
    this.changeDetectorRef.detectChanges();
  }

  public setMillValue(value: string, i: number) {
    this.selectedMill = value;
    this.harvestEvents[i].values[2].value = value;
    this.changeDetectorRef.detectChanges();
  }

  public createEvent() {
    this.disabledAfterClick = true;
    console.log('harvestEvents', this.harvestEvents);
    if (!this.mill.includes(this.selectedMill)) {
      this.harvestEventsLookupService
        .addMill({ body: { name: this.selectedMill } })
        .pipe(take(1))
        .subscribe();
    }
    this.harvestEvents.forEach((item, i) => {
      item.isDone = false;
      item.isSystem = false;
      if (
        item.type === this.eventTypesEnum.FlushWaterLine &&
        item.values[this.idByParameterName('isRecurring', i)].value === 'false'
      ) {
        item.values.length = 2;
      }
    });

    const openedCrops = this.getCropIds(this.selectedHouseIds);
    this.harvestService
      .addEvent({
        body: {
          harvestEvents: this.harvestEvents,
          harvestIds: openedCrops,
        },
      })
      .subscribe((res) => {
        if (res.errors && res.errors?.length > 0) {
          this.closeModal();
          this.showErrorModal(this.findHouseNames(res.errors), true);
          this.onError.emit(this.errorContent);
          this.disabledAfterClick = false;
          this.changeDetectorRef.detectChanges();
        } else {
          this.isShowBeforeCloseModal = false;
          this.closeModal();
          this.showNotificationModal();
          this.onNotification.emit(this.notificationContent);
          this.onEventCreated.emit(true);
          this.disabledAfterClick = false;
          this.changeDetectorRef.detectChanges();
        }
      });
  }

  public get getMaxHeight() {
    return (
      this.popupBody.nativeElement.offsetHeight +
      this.popupBody.nativeElement.offsetTop -
      182 +
      'px'
    );
  }

  public getCropIds(houses) {
    const cropIds = [];

    houses.forEach((id) => {
      cropIds.push(
        Math.max(
          ...this.customerSelectorsService.housesList
            .find((house) => house.id === id)
            .crops.map((crop) => crop.cropUniqueId)
        )
      );
    });
    return cropIds;
  }

  public addEvent() {
    if (this.eventType === EventType.AddFeed) {
      this.harvestEvents.push({
        date: this.selectDate,
        type: EventType.AddFeed,
        birdAge: null,
        values: [
          { name: 'Additives', value: this.additives[0] },
          { name: 'Coccidiostat', value: this.coccidiostat[0] },
          { name: 'Mill', value: this.mill[0] },
          { name: 'Ration', value: this.ration[0] },
          { name: 'Kg', value: null },
          { name: 'BatchNumber', value: null },
        ],
      });
    }
    if (this.eventType === EventType.AddMedication) {
      this.harvestEvents.push({
        date: this.selectDate,
        type: EventType.AddMedication,
        birdAge: null,
        values: [
          { name: 'Disease', value: this.medicationDisease[0] },
          { name: 'Duration', value: this.medicationDuration[0] },
          { name: 'Medication', value: this.antibiotics[0] },
        ],
      });
    }
    if (this.eventType === EventType.AddThinning) {
      this.harvestEvents.push({
        date: this.selectDate,
        birdAge: null,
        type: EventType.AddThinning,
        values: [
          { name: 'totalKg', value: null },
          { name: 'thinnedValue', value: '1' },
        ],
      });
    }
    if (this.eventType === EventType.AddNutraceutical) {
      this.harvestEvents.push({
        date: this.selectDate,
        type: EventType.AddNutraceutical,
        birdAge: null,
        values: [
          { name: 'Duration', value: this.nutraceuticalDuration[0] },
          { name: 'Nutraceutical', value: this.nutraceutical[0] },
          { name: 'Reason', value: this.reason[0] },
        ],
      });
    }
    if (this.eventType === EventType.AddVaccination) {
      this.selectedVaccinationDisease = this.vaccinationDisease[0];
      this.selectedType = this.types[0];
      this.selectedVaccine = this.vaccine[0];
      this.harvestEvents.push({
        date: this.selectDate,
        type: EventType.AddVaccination,
        birdAge: null,
        values: [
          { name: 'Disease', value: this.vaccinationDisease[0] },
          { name: 'Type', value: this.types[0] },
          { name: 'Vaccine', value: this.vaccine[0] },
        ],
      });
    }
    if (this.eventType === EventType.FlushWaterLine) {
      this.harvestEvents.push({
        date: this.selectDate,
        type: EventType.FlushWaterLine,
        birdAge: null,
        values: [
          { name: 'Comment', value: '' },
          { name: 'Daily Estimate', value: '' },
          { name: 'isRecurring', value: 'false' },
        ],
      });
    }
    if (this.eventType === EventType.WaterSanitation) {
      this.harvestEvents.push({
        date: this.selectDate,
        type: EventType.WaterSanitation,
        birdAge: null,
        values: [{ name: 'Comment', value: '' }],
      });
    }
    if (this.eventType === EventType.PlannedThinningAges) {
      this.harvestEvents.push({
        date: this.selectDate,
        type: EventType.PlannedThinningAges,
        birdAge: null,
      });
    }
    if (this.eventType === EventType.PlannedClearingAge) {
      this.harvestEvents.push({
        date: this.selectDate,
        type: EventType.PlannedClearingAge,
        birdAge: null,
        values: [],
      });
    }
    if (this.eventType === EventType.PlannedHandWeighingOfBirds) {
      this.harvestEvents.push({
        date: this.selectDate,
        type: EventType.PlannedHandWeighingOfBirds,
        birdAge: null,
        values: [
          { name: 'Number Of Birds', value: null },
          { name: 'Average Weight', value: null },
        ],
      });
    }
    if (this.eventType === EventType.PlannedFootpadScoringOfBirds) {
      this.harvestEvents.push({
        date: this.selectDate,
        type: EventType.PlannedFootpadScoringOfBirds,
        birdAge: null,
        values: [
          { name: 'Score 0', value: null },
          { name: 'Score 1', value: null },
          { name: 'Score 2', value: null },
          { name: 'Number Of Zone', value: null },
        ],
      });
    }
    if (this.eventType === EventType.Brooding) {
      let durationValue = 10;
      if (+this.broodingService.broodingDurationRemaining > 10) {
        durationValue = 10;
      } else {
        durationValue = +this.broodingService.broodingDurationRemaining;
      }
      this.harvestEvents.push({
        date: this.getDateToAddBrooding(),
        type: EventType.Brooding,
        birdAge: null,
        values: [
          {
            name: 'zonesIdx',
            value: '',
          },
          {
            name: 'Duration',
            value: String(durationValue),
          },
        ],
      });
    }
    if (this.eventType === EventType.OtherEvents) {
      this.harvestEvents.push({
        date: this.selectDate,
        type: EventType.OtherEvents,
        birdAge: null,
        values: [
          { name: 'Comment', value: '' },
          { name: 'isRecurring', value: 'false' },
        ],
      });
    }
    this.changeDetectorRef.detectChanges();
  }

  public getDateToAddBrooding() {
    if (
      this.harvestEvents.filter((item) => item.type === EventType.Brooding)
        .length === 0
    ) {
      console.log('NO BROODING EVENTS');
      return moment(this.currentHarvest.startDate).format(EVENT_DATE_FORMAT);
    } else {
      const previousBroodingEvent: any = this.harvestEvents.filter(
        (item) => item.type === EventType.Brooding
      )[
        this.harvestEvents.filter((item) => item.type === EventType.Brooding)
          .length - 1
      ];
      return moment(previousBroodingEvent.date)
        .add(+previousBroodingEvent.values[1].value, 'days')
        .format(EVENT_DATE_FORMAT);
    }
  }

  public plannedClearingEventChanged(value, harvestEventsIndex) {
    if (
      !this.harvestEvents[harvestEventsIndex].values.find(
        (item) => item.name === 'Comment'
      )
    ) {
      this.harvestEvents[harvestEventsIndex].values.push({
        name: 'Comment',
        value: '',
      });
    }
    this.harvestEvents[harvestEventsIndex].values[0].value = value;
  }

  public isShowAddEventButton() {
    switch (this.eventType) {
      case EventType.FlushWaterLine:
        return (
          this.harvestEvents.length <
          this.cropBehaviourService.possibleEventsLeft(this.eventType)
        );
      case EventType.WaterSanitation:
        return (
          this.harvestEvents.length <
          this.cropBehaviourService.possibleEventsLeft(this.eventType)
        );
      case EventType.PlannedThinningAges:
        return (
          this.harvestEvents.length <
          this.cropBehaviourService.possibleEventsLeft(this.eventType)
        );
      case EventType.PlannedClearingAge:
        return (
          this.harvestEvents.length <
          this.cropBehaviourService.possibleEventsLeft(this.eventType)
        );
      case EventType.PlannedHandWeighingOfBirds:
        return (
          this.harvestEvents.length <
          this.cropBehaviourService.possibleEventsLeft(this.eventType)
        );
      case EventType.PlannedFootpadScoringOfBirds:
        return (
          this.harvestEvents.length <
          this.cropBehaviourService.possibleEventsLeft(this.eventType)
        );
      case EventType.OtherEvents:
        return (
          this.harvestEvents.length <
          this.cropBehaviourService.possibleEventsLeft(this.eventType)
        );
      case EventType.AddFeed:
        return (
          this.harvestEvents.length <
          this.cropBehaviourService.possibleEventsLeft(this.eventType)
        );
      case EventType.AddMedication:
        return (
          this.harvestEvents.length <
          this.cropBehaviourService.possibleEventsLeft(this.eventType)
        );
      case EventType.AddThinning:
        return (
          this.harvestEvents.length <
          this.cropBehaviourService.possibleEventsLeft(this.eventType)
        );
      case EventType.AddNutraceutical:
        return (
          this.harvestEvents.length <
          this.cropBehaviourService.possibleEventsLeft(this.eventType)
        );
      case EventType.AddVaccination:
        return (
          this.harvestEvents.length <
          this.cropBehaviourService.possibleEventsLeft(this.eventType)
        );
      case EventType.Brooding:
        // return (
        //   this.harvestEvents.length <
        //     this.cropBehaviourService.possibleEventsLeft(this.eventType) &&
        //   +this.broodingService.broodingDurationRemaining > 0
        // );
        return false;
    }
  }

  public ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public get cropNumber() {
    return this.cropBehaviourService.cropNumber;
  }

  public countryChanged(value: string) {
    this.harvestEvents = [];
    this.addEvent();
    this.selectedCountry = value;
    this.types = Object.keys(this.vaccination[this.selectedCountry]);
    this.selectedType = this.types[0];
    console.log('countryChanged');
    this.typeChanged(this.selectedType, 0);
    this.changeDetectorRef.detectChanges();
  }

  public typeChanged(selectedRoute: string, i?: number) {
    this.selectedType = selectedRoute;
    this.vaccinationDisease = Object.keys(
      this.vaccination[this.selectedCountry][this.selectedType]
    );
    if (!this.vaccinationDisease.includes('Other')) {
      this.vaccinationDisease.push('Other');
    }
    this.harvestEvents[i].values[1].value = this.selectedType;
    this.selectedVaccinationDisease = this.vaccinationDisease[0];
    this.diseaseChanged(this.selectedVaccinationDisease, i);
    this.changeDetectorRef.detectChanges();
  }

  public diseaseChanged(selectedVaccinationDisease: string, i?: number) {
    this.selectedVaccinationDisease = selectedVaccinationDisease;
    if (selectedVaccinationDisease !== 'Other') {
      this.vaccine =
        this.vaccination[this.selectedCountry][this.selectedType][
          this.selectedVaccinationDisease
        ];
    } else {
      this.vaccine = ['Other'];
    }
    if (!this.vaccine.includes('Other')) {
      this.vaccine.push('Other');
    }
    this.harvestEvents[i].values[0].value = this.selectedVaccinationDisease;
    this.selectedVaccine = this.vaccine[0];
    this.vaccineChanged(this.selectedVaccine, i);
    this.changeDetectorRef.detectChanges();
  }

  public vaccineChanged(selectedVaccine: string, i?: number) {
    this.selectedVaccine = selectedVaccine;
    this.harvestEvents[i].values[2].value = this.selectedVaccine;
    this.changeDetectorRef.detectChanges();
  }

  public toggleRecurrentMode(i: number) {
    // ============== FLUSH ==================
    if (this.eventType === EventType.FlushWaterLine) {
      this.harvestEvents[i].values[this.idByParameterName('isRecurring', i)]
        .value === 'true'
        ? (this.harvestEvents[i].values[
            this.idByParameterName('isRecurring', i)
          ].value = 'false')
        : (this.harvestEvents[i].values[
            this.idByParameterName('isRecurring', i)
          ].value = 'true');
      if (
        this.harvestEvents[i].values[this.idByParameterName('isRecurring', i)]
          .value === 'true'
      ) {
        this.harvestEvents[i].values.push(
          { name: 'startDate', value: moment().format(DATE_FORMAT) },
          { name: 'endDate', value: moment().format(DATE_FORMAT) },
          { name: 'intervalDays', value: '1' },
          { name: 'endBirdAge', value: null },
          { name: 'startBirdAge', value: null }
        );
      } else {
        this.harvestEvents[i].values.length = 3;
      }
      // ================= OTHER ====================
    } else if (this.eventType === EventType.OtherEvents) {
      this.harvestEvents[i].values[this.idByParameterName('isRecurring', i)]
        .value === 'true'
        ? (this.harvestEvents[i].values[
            this.idByParameterName('isRecurring', i)
          ].value = 'false')
        : (this.harvestEvents[i].values[
            this.idByParameterName('isRecurring', i)
          ].value = 'true');
      if (
        this.harvestEvents[i].values[this.idByParameterName('isRecurring', i)]
          .value === 'true'
      ) {
        this.harvestEvents[i].values.push(
          { name: 'startDate', value: moment().format(DATE_FORMAT) },
          { name: 'endDate', value: moment().format(DATE_FORMAT) },
          { name: 'intervalDays', value: '1' },
          { name: 'endBirdAge', value: null },
          { name: 'startBirdAge', value: null }
        );
      } else {
        this.harvestEvents[i].values.length = 2;
      }
    }

    console.log('harvestEvents after toggle', this.harvestEvents);
    this.changeDetectorRef.detectChanges();
  }

  public eventTypeChange(value: EventType) {
    if (value === EventType.Brooding) {
      this.selectedHouseIds = [this.getCurrentHouse.facilityId];
      this.houses = [];
      this.houses = this.originalHouses.filter(
        (h) => h.facilityId === this.customerSelectorsService.currentHouse.id
      );
    } else {
      this.houses = this.originalHouses;
    }
    this.houses = [...this.houses];
    this.isTouched = true;
    this.harvestEvents = [];
    this.eventType = value;
    this.addEvent();
    this.birdAge = moment(
      this.currentHarvest.closeDate ? this.maxDate : this.nowDate
    ).diff(this.startDate, 'days');
    this.setPopulatedValues();
    if (this.eventType === EventType.AddVaccination) {
      this.countryChanged(this.selectedCountry);
      this.setPopulatedValues();
    }
    this.changeDetectorRef.detectChanges();
  }

  public closeRemoveModal() {
    this.isShowBeforeCloseModal = false;
    this.onCloseModal.emit();
  }

  public removeEvent(i: number) {
    this.harvestEvents.splice(i, 1);
  }

  public setSelectedZones(event, i) {
    this.harvestEvents[i].values[3].value = event.join();
  }
}

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import * as moment from 'moment';
import { HarvestFullResponse } from '../../../../../services/api/models/harvest-full-response';
import { CustomerSelectorsService } from '../../../../../services/customer-selectors/customer-selectors.service';
import { PermissionService } from '../../../../../services/user/permission-service';
import { IExtendedHarvestEventDto } from '../add-event-modal.component';
import { HarvestEvent } from '../../../../../services/api/models/harvest-event';
import { EventFunctionalModes } from '../../events.component';
import { HarvestUpdateRequest } from '../../../../../services/api/models/harvest-update-request';
import {
  EpochStateModel,
  EpochStateModelMinMaxMode,
} from '../../../time-picker/epoch-state-model';
import { TIME_12_PM } from '../../../modals/add-thinning-modal/add-thinning-modal.component';
import { EVENT_DATE_FORMAT } from '../../../../../utils/string-utils';
import { TranslationService } from '../../../../../services/translations/translation.service';

@Component({
  selector: 'planned-clearing-age',
  templateUrl: 'planned-clearing-age.component.html',
  styleUrls: ['planned-clearing-age.component.scss'],
})
export class PlannedClearingAgeComponent implements OnChanges {
  @Input() public currentHarvest: HarvestFullResponse;
  @Input() public event: IExtendedHarvestEventDto | HarvestEvent;
  @Input() public rowIndex: number;
  @Input() public possibleDeleteFirst: boolean = false;
  @Input() public day0Date: Date;
  @Input() public functionalMode: EventFunctionalModes =
    EventFunctionalModes.addEvent;

  @Output() public removeEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() public dateChanged: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() public birdAgeChanged: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() public onCommentChanged: EventEmitter<string> =
    new EventEmitter<string>();
  public epochSelectedDate = new EpochStateModel(
    EpochStateModelMinMaxMode.Time,
    Date.parse(TIME_12_PM) / 1000
  );
  @Input() public maxDate: Date;
  @Input() public startDate: Date;
  public birdAge: number = 0;
  public selectedDate: Date;
  public readonly systemNowDate = new Date();
  public isTouched: boolean = false;
  public EventFunctionalModes = EventFunctionalModes;

  // public updatedCrop: HarvestUpdateRequest = {
  //   closeDate: null,
  //   comments: null,
  //   harvestDeliveryNoteRequestList: [],
  //   hatcheryVaccines: [],
  //   inactivatedVaccineId: null,
  //   integratorId: null,
  //   maxStockingDensity: null,
  //   vaccineId: null,
  //   startDate: '',
  // };

  public isDateChanged: boolean = true;

  @ViewChild('inputContainer')
  public inputContainer: ElementRef;

  constructor(
    public customerSelectorsService: CustomerSelectorsService,
    public readonly permissionService: PermissionService,
    public readonly translationService: TranslationService
  ) {}

  public ngOnChanges(changes: SimpleChanges) {
    if (
      changes.event ||
      changes.day0Date ||
      changes.maxDate ||
      changes.startDate
    ) {
      this.epochSelectedDate = new EpochStateModel(
        EpochStateModelMinMaxMode.Time,
        Date.parse(this.event.date) / 1000
      );
      switch (this.functionalMode) {
        case EventFunctionalModes.editEvent: {
          this.setEditEventdate();
          break;
        }
        case EventFunctionalModes.addEvent: {
          console.log('EVENT TO ADD: ', this.event);
          this.setAddEventDate();
          break;
        }
        case EventFunctionalModes.wizard: {
          this.setWizardDate();
          break;
        }
      }
    }
  }

  public setEditEventdate() {
    console.log('EVENT TO EDIT: ', this.event);
    this.birdAge = this.event.birdAge;
    this.selectedDate = moment(this.event.date).toDate();
  }

  public setAddEventDate() {
    this.selectedDate = this.maxDate
      ? moment(this.maxDate)
          .set({
            hour: +moment(this.startDate).format('HH'),
            minute: +moment(this.startDate).format('mm'),
            seconds: +moment(this.startDate).format('ss'),
          })
          .toDate()
      : moment(this.systemNowDate)
          .set({
            hour: +moment(this.startDate).format('HH'),
            minute: +moment(this.startDate).format('mm'),
            seconds: +moment(this.startDate).format('ss'),
          })
          .toDate();

    this.birdAge = !this.event.birdAge
      ? moment(this.selectedDate).diff(this.startDate, 'days')
      : this.event.birdAge;
    this.event.birdAge = !this.event.birdAge
      ? this.birdAge
      : this.event.birdAge;
  }

  public changeBirdAge(value) {
    const maxBirdAge = this.maxDate
      ? moment(this.maxDate).diff(this.startDate, 'days')
      : 80;

    this.birdAge = Number(value) > maxBirdAge ? maxBirdAge : Number(value);
    this.isTouched = false;

    this.birdAgeChanged.emit(this.birdAge);
  }

  public setWizardDate() {
    this.startDate = this.day0Date;
    this.selectedDate = moment(this.day0Date)
      .add(this.event.birdAge, 'days')
      .toDate();
    this.birdAge = moment(this.selectedDate).diff(this.startDate, 'days');
    this.changeSelectedDate(this.selectedDate, true);
  }

  public onEpochChanged(epoch) {
    this.epochSelectedDate = epoch;
    const timeString = this.convertEpochToStringTime(epoch);
    let dateString = moment(this.selectedDate).format(EVENT_DATE_FORMAT);
    let dateStringWithSelectedTime =
      dateString.split('').slice(0, 11).join('') + timeString;
    this.selectedDate = moment(dateStringWithSelectedTime).toDate();

    this.dateChanged.emit(moment(this.selectedDate).format(EVENT_DATE_FORMAT));
  }

  public convertEpochToStringTime(epoch) {
    return moment.unix(epoch.epochTime).utc().format('HH:mm');
  }

  public idByParameterName(name: string, specificArray?) {
    if (specificArray) {
      return specificArray.findIndex((value) => {
        return value.name === name;
      });
    } else {
      return this.event.values.findIndex((value) => {
        return value.name === name;
      });
    }
  }

  public commentChanged(value: string) {
    this.onCommentChanged.emit(value);
  }

  public changeSelectedDate(date: Date, isWizard?: boolean) {
    this.isTouched = true;
    this.selectedDate = date;
    this.onEpochChanged(this.epochSelectedDate);
    if (this.functionalMode !== EventFunctionalModes.wizard) {
      this.birdAge = moment(date)
        .set({
          hour: +moment(this.startDate).format('HH'),
          minute: +moment(this.startDate).format('mm'),
          seconds: +moment(this.startDate).format('ss'),
        })
        .diff(this.startDate, 'days');
    } else {
      this.birdAge = moment(date)
        .set({
          hour: +moment(this.day0Date).format('HH'),
          minute: +moment(this.day0Date).format('mm'),
          seconds: +moment(this.startDate).format('ss'),
        })
        .diff(moment(this.day0Date).format(EVENT_DATE_FORMAT), 'days');
    }
  }

  public onRemoveEvent() {
    this.removeEvent.emit();
  }
}

<div class="login-wrapper">
  <div class="login-tab">
    <div class="login-tab__img"
         [class.none]="loginView === loginViewEnum.privacy || loginView === loginViewEnum.eula">
      <img src="./assets/img/login/chicken.46b8937b.png">
    </div>
    <div class="login-tab__form"
         [class.width-100]="loginView === loginViewEnum.privacy || loginView === loginViewEnum.eula">
      <div class="language-wrapper">
        <change-language-dropdown></change-language-dropdown>
      </div>
      <form class="login-tab__form-wrapper" *ngIf="loginView === loginViewEnum.login" [formGroup]="form"
            (ngSubmit)="submit()">
        <div class="login-tab__form-logo">
          <img src="assets/img/logo/logo.svg">
          <div class="display-flex sense-hub">
            <span class="sense">Sense</span>
            <span>Hub</span>
          </div>
          <div class="poultry">poultry</div>
        </div>
        <div class="login-tab__form-title">{{'LOGIN.TITLE.Keep_your_farm_in_good_hands' | translate}}</div>
        <div
          class="login-tab__form-subtitle display-flex flex-column">
          {{'LOGIN.TITLE.Harvest_the_true_yield_of_your_Poultry_enterprise' | translate}}
          <span class=" note-text"
                *ngIf="currentNote?.length < 1 && isShowNote">{{'SETTINGS.DASHBOARD_UI.SENSE_HUB_POULTRY_RELEASE' | translate}}&nbsp;{{currentAppVersion}}</span>
          <span *ngIf="currentNote?.length > 0  && isShowNote">
            <span class="note-text" *ngIf="currentNote[0]?.type !== SystemNoteType.Custom">{{'SETTINGS.DASHBOARD_UI.' + currentNote[0]?.type | translate}}</span>
            <span class="note-text" *ngIf="currentNote[0]?.type === SystemNoteType.Custom">{{currentNote[0]?.content}}</span>
            <span class="note-text"
                  *ngIf="currentNote[0]?.type === SystemNoteType.SenseHubPoultryRelease">&nbsp;{{currentAppVersion}}</span>
            <span class="note-text"
                  *ngIf="currentNote[0]?.type === SystemNoteType.NewReleaseDeploymentIsPlanned">&nbsp;{{currentNote[0]?.endDate | date:  translationService.translationShortDateFormat}}</span>
          </span>
        </div>
        <div class="login-tab__form-error"
             *ngIf="(showAuthFail$ | async) && isCustomerDeactivated === false">{{'LOGIN.ERROR.Failed_to_login_Authentication_Failed' | translate}}</div>
        <div class="login-tab__form-error"
             *ngIf="(showAuthFail$ | async) && isCustomerDeactivated === true">{{'LOGIN.ERROR.no_active_customers_for_this_user' | translate}}</div>
        <div class="login-tab__form-input">
          <div *ngIf="emailInput.value || emailInputFocus"
               class="login-tab__form-input-placeholder">{{'LOGIN.FORM.Email_Address' | translate}}</div>
          <input #emailInput
                 [(ngModel)]="emailInput.value"
                 (focusin)="emailInputFocus = true"
                 (focusout)="emailInputFocus = false"
                 formControlName="userName"
                 [placeholder]="'LOGIN.FORM.Email_Address' | translate"
                 type="email"
                 class="login-input">
          <img src="assets/img/login/user_icon.6d16e2a5.svg">
          <div *ngIf="emailInputFocus" class="login-tab__form-input-underline"></div>
          <div *ngIf="form.get('userName').dirty && form.get('userName').invalid"
               class="login-tab__form-input-underline red"></div>
        </div>
        <div *ngIf="form.get('userName').dirty && form.get('userName').invalid" class="login-tab__form-input-error">
          {{'LOGIN.FORM.Email_Id_should_be_valid_one' | translate}}
        </div>
        <div class="login-tab__form-input">
          <div *ngIf="passwordInput.value || passwordInputFocus" class="login-tab__form-input-placeholder">
            {{'LOGIN.FORM.Password' | translate}}
          </div>
          <input #passwordInput
                 [(ngModel)]="passwordInput.value"
                 (focusin)="passwordInputFocus = true"
                 (focusout)="passwordInputFocus = false"
                 formControlName="password"
                 [placeholder]="'LOGIN.FORM.Password' | translate"
                 [type]="passwordType"
                 class="login-input">
          <img src="assets/img/login/eye_icon.009fd067.svg" (click)="showHidePass()">
          <div *ngIf="passwordInputFocus" class="login-tab__form-input-underline"></div>
          <div *ngIf="form.get('password').dirty && form.get('password').invalid"
               class="login-tab__form-input-underline red"></div>
        </div>
        <!--        <div>-->
        <!--          <small class="warn">{{'COMMON.PROFILE.password_hint' | translate }}</small>-->
        <!--        </div>-->
        <div *ngIf="form.get('password').dirty && form.get('password').invalid" class="login-tab__form-input-error">
          {{'LOGIN.FORM.Password_should_be_valid_one' | translate}}
        </div>
        <div class="login-tab__form-remember-forgot">
          <div></div>
          <span class="forgot"
                (click)="loginView = loginViewEnum.forgot; form.markAsPristine()">{{'LOGIN.FORM.FORGOT_PASSWORD' | translate}}</span>
        </div>
        <div class="login-tab__form-remember-forgot">
          <div class="switch">
            <input id="switch-remember" type="checkbox" class="switch-input">
            <label for="switch-remember" class="switch-label">
              <span>
                {{'LOGIN.FORM.REMEMBER_ME' | translate}}
              </span>
            </label>
          </div>
          <div class="privacy-wrapper-auth">
            <div class="privacy"
                 (click)="loginView = loginViewEnum.privacy; form.markAsPristine()">{{'LOGIN.FORM.Privacy_Policy' | translate}}</div>
            <span class="color-gray">&nbsp;|&nbsp;</span>
            <div class="privacy"
                 (click)="loginView = loginViewEnum.eula; form.markAsPristine()">{{'LOGIN.FORM.EULA' | translate}}</div>
          </div>
        </div>
        <div class="login-tab__form-button">
          <button class="blue-rounded-button width-100" type="submit"
                  [disabled]="form.invalid">{{'LOGIN.FORM.LOGIN' | translate}}</button>
        </div>
      </form>
      <div *ngIf="loginView === loginViewEnum.forgot" class="login-tab__forgot">
        <div class="login-tab__forgot-logo">
          <img src="assets/img/login/forgot-logo.svg">
        </div>
        <div class="login-tab__form-title">{{'FORGOT.TITLE.Forgot_Your_Password' | translate}}</div>
        <div class="login-tab__forgot-subtitle">
          {{'FORGOT.TITLE.Enter_your_registered_email_address_and_we_will_send' | translate}}
          <br> {{'FORGOT.TITLE.You_a_email_for_reset_password' | translate}}
        </div>

        <form class="login-tab__forgot-form" [formGroup]="formForgot" (ngSubmit)="forgotPassword()">
          <div class="login-tab__form-input">
            <div *ngIf="emailInputForgot.value" class="login-tab__form-input-placeholder">
              {{'FORGOT.FORM.Email_Address' | translate}}
            </div>
            <input #emailInputForgot
                   [(ngModel)]="emailInputForgot.value"
                   (focusin)="emailInputForgotFocus = true"
                   (focusout)="emailInputForgotFocus = false"
                   (click)="isEmailSent = false"
                   formControlName="email"
                   type="email"
                   class="login-input">
            <div *ngIf="emailInputForgotFocus" class="login-tab__form-input-underline"></div>
<!--            <div *ngIf="isEmailSent"-->
<!--                 class="login-tab__form-input-underline red"></div>-->
          </div>
          <div *ngIf="isEmailSent"
               class="login-tab__form-input-error">
            {{'LOGIN.FORM.Please_check_your_email_in_order_to_proceed_reset_password' | translate}}
          </div>
          <div class="login-tab__forgot-form-button">
            <button class="blue-rounded-button width-100" type="submit"
                    [disabled]="formForgot.invalid || isEmailSent">{{'FORGOT.FORM.Send' | translate}}</button>
          </div>
        </form>
        <div class="login-tab__forgot-back" (click)="loginView = loginViewEnum.login; form.markAsPristine()">
          {{'FORGOT.FORM.Back_to_Login' | translate}}
        </div>
      </div>
      <div class="height-80 width-80" *ngIf="loginView === loginViewEnum.privacy || loginView === loginViewEnum.eula">
        <privacy-component
          [privacyAgreementText]="privacyAgreementText"
          [EULAAgreementText]="EULAAgreementText"
          [inviteView]="loginView"
          (onGoToLogin)="loginView = loginViewEnum.login">
        </privacy-component>
      </div>

      <div class="login-tab__form-logo2">
        <img [src]="getLogo">
        <essential-logo></essential-logo>
      </div>
    </div>
  </div>
</div>
<copyright></copyright>

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject, throwError } from 'rxjs';
import { IProfile } from '../profile-modal.component';
import { catchError, takeUntil } from 'rxjs/operators';
import { MyService } from '../../../../services/api/services/my.service';
import { HttpErrorResponse } from '@angular/common/http';
import { LanguageEnum } from 'src/app/services/api/models/language-enum';
import {
  PaginationName,
  USER_RES_KEY,
  UserDetailsService,
} from '../../../../services/user/user-details.service';
import { DropdownListItem } from '../../dropdown-list/dropdown-list-model';
import { TranslationService } from '../../../../services/translations/translation.service';
import { NamedValue } from '../../../../services/api/models/named-value';
import { UserService } from '../../../../services/api/services/user.service';
import { PermissionService } from '../../../../services/user/permission-service';
import { PermissionName } from '../../../../services/api/models/permission-name';
import { CurrentCropBehaviourService } from '../../../../services/harvest/current-crop-behaviour.service';

export enum SettingType {
  Language = 'Language',
  Paging = 'Paging',
}

@Component({
  selector: 'ui-defaults',
  templateUrl: 'ui-defaults.component.html',
  styleUrls: ['ui-defaults.component.scss'],
})
export class UiDefaultsComponent implements OnDestroy, OnInit {
  @Input() public profileData: IProfile;

  public isChangedLanguage: boolean = false;

  public SettingType = SettingType;

  public openedSettingType: SettingType = SettingType.Language;

  public LanguageEnum = LanguageEnum;

  public selectedLanguage: LanguageEnum = LanguageEnum.EnUs;

  private destroyed$ = new Subject();

  public showSaveFail$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  public isChangedPaginationConfig: boolean = false;

  public countersElementsPerPage: number[] = [5, 10, 20, 50, 100];
  public countersElementsPerPageDropdown: DropdownListItem[] = [];

  public updateUiDefaults: PermissionName = PermissionName.UpdateUiDefaults;
  public readUiDefaults: PermissionName = PermissionName.ReadUiDefaults;

  public allPagesValue: DropdownListItem = {
    value: '5',
    disabled: false,
    icon: null,
    displayValue:
      '5' + ' ' + this.translationService.translate('PROFILE.Defaults.Rows'),
  };

  public paginationConfig: Array<NamedValue> = [];

  constructor(
    public myService: MyService,
    public userService: UserService,
    public userDetailsService: UserDetailsService,
    public translationService: TranslationService,
    public permissionService: PermissionService,
    public currentCropBehaviourService: CurrentCropBehaviourService
  ) {}

  public ngOnInit() {
    this.selectedLanguage = this.profileData.language;
    this.paginationConfig = JSON.parse(
      localStorage.getItem(USER_RES_KEY)
    ).paginationConfig;

    if (!this.currentCropBehaviourService.isSuperAdmin) {
      this.paginationConfig = this.paginationConfig.filter(
        (config) => config.name !== PaginationName.AdminSettings
      );
    }

    this.countersElementsPerPage.forEach((el) => {
      this.countersElementsPerPageDropdown.push({
        value: el.toString(),
        disabled: false,
        icon: null,
        displayValue:
          el + ' ' + this.translationService.translate('PROFILE.Defaults.Rows'),
      });
    });

    const paginationValue = this.paginationConfig[0].value;
    this.allPagesValue.value = this.paginationConfig.every(
      (config) => config.value === paginationValue
    )
      ? paginationValue
      : '';
  }

  public setAllPagesPaginationConfig(value) {
    this.allPagesValue.value = value;
    this.paginationConfig.forEach((el) => {
      el.value = value;
    });
  }

  public updateMyUserDetails() {
    this.myService
      .updateMyUserDetails({
        body: {
          firstName: this.profileData.firstName,
          lastName: this.profileData.lastName,
          phone: this.profileData.phone,
          language: this.selectedLanguage,
        },
      })
      .pipe(
        takeUntil(this.destroyed$),
        catchError(this.showSaveError.bind(this))
      )
      .subscribe((res) => {
        this.userDetailsService.setUserDetails();
        this.isChangedLanguage = false;
      });
  }

  public updatePaginationConfig() {
    this.userService
      .updatePaginationConfig({
        userId: this.profileData.id,
        body: this.paginationConfig,
      })
      .pipe(
        takeUntil(this.destroyed$),
        catchError(this.showSaveError.bind(this))
      )
      .subscribe((res) => {
        this.userDetailsService.setUserDetails();

        const paginationValue = this.paginationConfig[0].value;
        this.allPagesValue.value = this.paginationConfig.every(
          (config) => config.value === paginationValue
        )
          ? paginationValue
          : '';

        this.isChangedPaginationConfig = false;
      });
  }

  public showSaveError(error: HttpErrorResponse) {
    this.showSaveFail$.next(true);
    return throwError(error);
  }

  public selectSettingType(type) {
    if (type === this.openedSettingType) {
      this.openedSettingType = null;
    } else {
      this.openedSettingType = type;
    }
  }

  public ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
